/* Provide sufficient contrast against white background */
body {
  font-family: 'Share Tech', sans-serif;
  font-size:16px;
  color:#222428;
}

input, select { font-size: 100%; }

.page-content {
  margin-top: 2em;
  min-height: calc(100vh - 10em);
}

/* add extra space after page-content */
.page-content::after,
#resultsCol::after { 
    content: '';
    background-color: transparent;
    display: block;
    height: 4em;
}

#accounts-signup,
#accounts-login,
#brokers-signup,
#brokers,
#notfound {
  padding-top:2em;
  padding-bottom:2em;
}

@media (min-width:992px) {

  #accounts-signup,
  #accounts-login,
  #brokers-signup,
  #brokers,
  #notfound {
      padding-top: 4em;
      padding-bottom: 4em;
      min-height: calc(100vh - 204px);
  }

}

header {
  background-color:#fff;
  position: sticky;
      top: 0;
      z-index: 100;
}

@media (min-width:992px) {
  header {
      position: sticky;
      top: 0;
      z-index: 100;
  }
}

/* Add XXL container fixed-width */
@media (min-width:1920px) { 
  .container {
    max-width: 1700px;
  }
}

@media (min-width:1091px) and (max-width:1319px) {
  
  ul.navbar-nav.flex-grow {
    margin-right: 1em;
  }

  .filters-container {
    margin-right:1.5em;
  }
}

@media (min-width:1320px) {
  ul.navbar-nav.flex-grow {
    margin-right: 2em;
  }
}

.jga-hero {
    margin-top:-2em;
  }

@media screen and (min-width: 1200px) {
  
  .page-content {
    /*margin-top: 4em;*/
  } 

  .jga-hero {
    margin-top:-4em;
  }

} 

h3 {
  font-family: 'Racing Sans One', sans-serif;
  font-size:1.5em;
  letter-spacing: 0.05em;
  transition: .2s all ease-in-out;
}

.grid-item:hover h3,
.grid-item:active h3 {
    color: #bc002d;
    /* filter: drop-shadow(0.06em 0 0 #d78196); */
    text-shadow: 0.07em 0 0px #e7b1bf;
    letter-spacing:2px;
}

.grid-item a.listing-link,
.grid-item a.listing-link:hover {
  color:inherit;
  text-decoration: none;
}

.grid-item a.listing-link {
  width:100%;
}

h6 {
  font-family: 'Share Tech', sans-serif;
  /* font-family: 'Racing Sans One', sans-serif; */
  font-size:1em;
  letter-spacing:1.25px;
  color:#666;
  margin:0;
}

a {
  color: #0366d6;
}

img {
  max-width:100%;
}


.btn {
  letter-spacing: 1px;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 0 1em;
  border: 3px white double !important;
}

.btn-light {
  color: #222428 !important;
}

.btn-dark {
  background-color:#222428;
  color: #fff !important;
}

.btn-dark:hover {
  background-color:#000;
}

@media (max-width: 768px) { /* create a helper class to make buttons full width of container on mobile */
    .btn.full-width {
        width: 100%;  
    }
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.grid-container {
    display: grid;
    grid-column-gap: 2em;
    grid-row-gap:4em;
    grid-template-columns: calc(50% - 1em) calc(50% - 1em);
    padding: 0 1em 0 0;
}
@media (min-width:1200px) {
  .grid-container {
    grid-template-columns: calc(33.33% - 1.33em) calc(33.33% - 1.33em) calc(33.33% - 1.33em);
  }
  
}

@media (min-width: 1920px) {
  .grid-container {
      grid-template-columns: calc(25% - 1.33em) calc(25% - 1.33em) calc(25% - 1.33em) calc(25% - 1.33em);
  }
}

.grid-item {
    font-size: 1em;
    text-align: left;
    color:inherit !important;
    position: relative;
    display: flex;
    filter: drop-shadow(0px 1px 1px #dedede );
    overflow: hidden;
    height: 100%;
}

.grid-item-container {
    background-color: #fff;
    border-radius: 0 1.5em 0 1.5em;
    clip-path: polygon(2em 0, 100% 0, 100% calc(100% - 2em), calc(100% - 2em) 100%, 0 100%, 0 2em);
    position: relative;
    height: 100%;
}

.grid-img-container {
  border-radius: 0.5em 2px 0;
  clip-path: polygon(2em 0, 100% 0, 100% calc(100% - 2em), calc(100% - 2em) 100%, 0 100%, 0 2em);
}

.grid-img-container::before {
    content: '';
    display: block;
    position: absolute;
    height: auto;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: inset 4px 4px 16px 8px rgba(0,0,0, 0.2);
    border-radius: 9% 1.5em 0 0;
}

.grid-img {
    width: 100%;
    max-width:100%;
    height: auto;
    border-radius: 0 1.5em 0 0;
}

.grid-item .isFavorited,
.grid-item .favorite,
.image-viewer .isFavorited,
.image-viewer .favorite {
  position: absolute;
  z-index: 1;
  top: 0.5em;
  right: 0.5em;
  color: #ccc;
  filter: drop-shadow(0px 0px 4px rgba(0,0,0,.4));
  font-size:2em;
  transition: 0.1s all ease-in-out;
  font-size:0;
}
/*
.grid-item .favorite:hover,
.image-viewer .favorite:hover
 {
  color:#FFF;
  text-decoration: none;
  filter: drop-shadow(0px 0px 4px rgba(0,0,0,.5));
}

.grid-item .favorite:active,
.image-viewer .favorite:active {
    filter: drop-shadow(0px 0px 4px rgba(255,122,140,1));
} */

label.labelFavorited {
  position: absolute;
  right: 55px;
  color: #fff;
  text-shadow: 0 0 4px rgba(0,0,0,.8);
  top: 17px;
  padding: 3px 8px;
  background-color: rgba(0,0,0,.5);
  border-radius: 3px;
  z-index: 1;
}

/* .grid-item .isFavorited,
.image-viewer .isFavorited {
  color:#bc002d;
  text-decoration: none;
  filter: drop-shadow(0px 0px 4px rgba(65,15,15,.6));
} */

.animated{
    
-webkit-animation-fill-mode:both;
-moz-animation-fill-mode:both;
-ms-animation-fill-mode:both;
-o-animation-fill-mode:both;
animation-fill-mode:both;
-webkit-animation-duration:3s;
-moz-animation-duration:3s;
-ms-animation-duration:3s;
-o-animation-duration:3s;
animation-duration:3s;  
}

@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-20px);
	}	25% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
    75% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
    100% {
		opacity: 0;
		-webkit-transform: translateX(20px);
	}
}

@-moz-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-20px);
	}	25% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
    75% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
    100% {
		opacity: 0;
		-webkit-transform: translateX(20px);
	}
}

@-o-keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-20px);
	}	25% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
    75% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
    100% {
		opacity: 0;
		-webkit-transform: translateX(20px);
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-20px);
	}	25% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
    75% {
		opacity: 1;
		-webkit-transform: translateX(0);
	}
    100% {
		opacity: 0;
		-webkit-transform: translateX(20px);
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	-moz-animation-name: fadeInLeft;
	-o-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}

.grid-item-content {
    padding: 1.5em 2em;
}

.grid-item-content .row {
  display: flex;
  align-items: center;
}

/* hover styles for Desktop only */
@media (min-width: 992px) {
  
  .grid-item {
    transition:0.2s all ease-in-out;
  }

  .grid-item:hover {
      filter: drop-shadow(0 4px 0 #efefef ) drop-shadow(2px 4px 4px rgba(0,0,0,0.125)) drop-shadow(0px 0px 16px rgba(0,0,0,0.2));
    /* old filter: drop-shadow(0 4px 0 #efefef ) drop-shadow(2px 8px 24px rgba(0,0,0,0.2)) drop-shadow(0px 8px 32px rgba(0,0,0,0.175)); */
      transform: translate3d(0, -6px, 0px);
  }

}

/* mobile responsive styles */
@media (max-width: 767px) {
  
  .grid-container {
    grid-template-columns: calc(50% - 1em) calc(50% - 1em);
  }

  .grid-img-container {
    clip-path: polygon(2em 0, 100% 0, 100% calc(100% - 2em), calc(100% - 2em) 100%, 0 100%, 0 2em);
  }

  .grid-item-content {
    padding:1.5em 1em 1em;
  }

  .grid-item .btn {
    margin: 1em 1em 0 1em;
    clip-path: polygon(5% 0, 100% 0, 100% 70%, 95% 100%, 0 100%, 0 30%);
  }

  .grid-item {
    overflow:visible;
  }

}

@media (max-width: 413px) {
  
  .grid-container {
    grid-template-columns: 100%;
  }
}

.temp-images-container {
    filter: drop-shadow(0 4px 0 #efefef ) drop-shadow(2px 4px 4px rgba(0,0,0,0.2)) drop-shadow(0px 0px 16px rgba(0,0,0,0.175));
    overflow: hidden;
}

.temp-images-container .image-viewer {
    background-color: #fff;
    border-radius: 0 1.5em 1.5em 1.5em;
    clip-path: polygon(2em 0, 100% 0, 100% calc(100% - 2em), calc(100% - 2em) 100%, 0 100%, 0 2em);
    position: relative;
}

.temp-images-container .main-img img {
  display:none;
}

.temp-images-container .main-img img:nth-child(2){
  display:block;
  width:100%;
  border-radius: 0 1.5em 0 0;
}

.temp-images-container .thumbs {
  display:inline-flex;
  flex-flow: wrap;
  align-items: center;
  padding: 1em 1em 2em 1em;
}

.temp-images-container .thumbs img {
  display: flex;
  max-width: 100px;
  border-radius: 0px;
}

.listing-content-full {
  margin-top:0em;
}

@media screen and (min-width:992px) {
  .listing-content-full {
    /*margin-top:4em;*/
  }
}

.listing-page h6.mainheading {
  font-size:2em;
}

.listing-page h3.mainheading {
  font-size:3em;
}

.listing-content-full .btn {
  margin-right:1em;
}

.ctaButtons {
  margin-bottom:2em;
}

.listing-content-full .btn:last-child {
  margin-right:0;
}


@media screen and (max-width:991px) {
  .ctaButtons {
      margin-bottom: 2em;
      margin-top: 3em;
  }

  .ctaButtons button:first-child {
    margin-bottom:1em;
  }
}

@media screen and (max-width:768px) {
  .copyLinkText {
    margin-top: 1em;
    display: block;
    text-align: center;
  }
}

@media screen and (min-width:991px) and (max-width: 1200px) {
  .copyLinkText {
    margin-left: 12em;
    margin-top: 1em;
    display: block;
    text-align: center;
  }
}

.contact-broker-modal .broker-profile-img {
  margin:0;
}

.contact-broker-modal .list-group-item {
  border-radius: 1em !important;
}

@media screen and (min-width:768px) {
  .modal-dialog.contact-broker-modal {
        width:80%;
        max-width:900px;
    }
}

.contact-broker-modal .broker-selection {
  margin-top:2em;
}

.contact-broker-modal .broker-selection li {
  margin-bottom:1em;
}

.contact-broker-modal .broker-selection li h4 {
  margin-top:1em;
}

.mobile-intro {
  margin-bottom: 1em;
}

.mobile-intro .row {
  align-items: flex-end;
}

.mobile-intro .row div:last-child {
  margin-bottom: 1em;
}

.related-vehicles {
  margin-top:6em;
}

.related-vehicles h2 {
    margin-bottom:1em;
}
.related-item h3 {
  font-size:1.2em;
}

.related-item {
  margin-bottom:2em;
}

footer {
  background-color:#222428;
  color:#fff;
  position: static;
  bottom:0;
  padding: 4em 0 3em 0;
  padding-top: 4em;
}

footer a {
  color:#fff;
  margin-left:8px;
  margin-right:8px;
}

footer a:first-of-type {
  margin-right:8px;
  margin-left:0;
}

footer a:last-of-type {
  margin-left:8px;
  margin-right:0;
}

footer a:hover {
  color:#ccc;
}

footer div .col:last-child {
  text-align: right;
  color:#aaa;
}

.loading-spinner {
  display: inline-block;
  width: 10em;
  left: calc(50% - 5em);
  position: relative;
  height: calc(100vh - 73px);
}

.google-map-listing-page {
  height: 150px;
  margin-top: 3em;
}

.google-map-broker-profile {
  height: 300px;
}

.broker-bio-section {
  margin-top:4em;
}

.loading-spinner:after {
  content: " ";
  display: inline-block;
  width: 10em;
  height: 10em;
  border-radius: 50%;
  border: 1em solid;
  border-color: #bc002d transparent #bc002d transparent;
  -webkit-animation: loading-spinner 1s infinite;
  animation: loading-spinner 1s infinite;
  top: calc(50% - 8em);
  position: relative;
}

@keyframes loading-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.page-wrapper {
  min-height: calc(100vh - 73px);
}

.filters-container {
    font-size: 1em;
    text-align: left;
    color: inherit !important;
    position: relative;
    display: flex;
   /* filter: drop-shadow(0 4px 0 #efefef ) drop-shadow(2px 4px 4px rgba(0,0,0,0.125)) drop-shadow(0px 0px 16px rgba(0,0,0,0.2)); */
    height:auto;   
}

.filters-inner {
    width: 100%;
   /* padding: 2em;*/
    background-color: #fff;
  /*  border-radius: 0 1.5em 0 1.5em; */
  /*  -webkit-clip-path: polygon(2em 0, 100% 0, 100% calc(100% - 2em), calc(100% - 2em) 100%, 0 100%, 0 2em); */
  /*  clip-path: polygon(2em 0, 100% 0, 100% calc(100% - 2em), calc(100% - 2em) 100%, 0 100%, 0 2em); */
    position: relative;
    overflow:hidden;
}

#filtersheading {
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1.25em 0;
}

#filterCol {
    position: fixed;
    z-index: 99;
}

#filterForm p:last-of-type {
  margin-top:8px;
}

.p-small {
  font-size:0.75em;
}

@media (min-width:992px) {
    
  #filterCol {
      height: calc(100vh - 100px);
      position: sticky;
      top: 100px;
      overflow-y: scroll;
  }

  #filtersheading {
      background-color: #fff;
      top: 0px;
      z-index: 99;
      padding:0;
    }

}

#clearFilters {
  line-height: 3em;
  color:#222428;
}


@media screen and (max-width:767px) {
  #filters {
    position:relative;
    margin-bottom:2em;
  }
  #filters .grid-item-container {
    width:100%;
    margin-right:0;
  }

  .grid-container {
    padding: 0 1em;
  }

}


aside .grid-item:hover {
    filter: drop-shadow(0 4px 0 #efefef ) drop-shadow(2px 4px 4px rgba(0,0,0,0.2)) drop-shadow(0px 0px 16px rgba(0,0,0,0.175));
    transform: translate3d(0, 0, 0);
}

aside .grid-item-container {
  padding:2em;
  margin-right:2em;
  width:100%;
  max-height: calc(100vh - 9em);
}

.list-group > .list-group {
  border-top:0;
  border-bottom:0;
}

.list-group > .list-group > .collapse > .list-group-item,
.list-group > .list-group > .collapsing > .list-group-item {
  border-radius:0;
  border-top:0;
}

.list-group-item:first-child {
  border-radius: 0;
}

.list-group-item:last-child {
  border-radius: 0;
}


.list-group:last-child {
  border-bottom:1px;
}

.list-group > .collapse,
.list-group > .collapsing {
    max-height: 200px;
    overflow: scroll;
}

.list-group > .collapse .form-check, 
.list-group > .collapsing .form-check,
.list-group > .collapse .form-check-label, 
.list-group > .collapsing .form-check-label {
    width: 100%;
}

.collapse > .list-group-item,
.collapsing > .list-group-item {
  background-color: #f9f9f9;
}

input::placeholder {
  color:#ccc !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
}

.tableLabel {
  font-weight:900;
}

.results-heading {
  margin-bottom:1.5em;
  background-color: #FFF;
  padding: 0.5em 0 1em;
}

.carousel .slide {
  background-color:#fff;
}

.carousel .slide img {
    max-width: 100%;
    vertical-align: top;
    border: 0;
    width: auto;
}

.carousel-slider {
    border-radius: 0 1.5em 0 0;
}

.jga-hero {
  margin-left: -15px;
  margin-right: -15px;
  background-color: #090909;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 4em;
  text-align:right;
}

.jga-hero img {
  max-height:60vh;
}
.jga-hero .hero-content {
  text-align: center;
}

.hero-content p {
    color: #aaa;
    margin-left: 1em;
    margin-right: 1em;
}

.hero-content a {
    display: block;
    margin-bottom: 1em;
    margin-right: 1em;
    margin-left: 1em;
}

@media (min-width:768px) {
  .hero-content a {
    display:inline-block;
  }

  .hero-content a:first-of-type {
      margin-right: 1em;
  }

}

.jga-hero h1 {
  text-transform: uppercase;
  letter-spacing: 5px;
  color:#ccc;
  font-size:1em;
}

.jga-hero h2 {
  color:#fff;
  font-size:3em;
  font-weight:900;
  margin-bottom:0.5em;
}

.jga-hero a {
  color:#FFF;
}

.jga-hero a:hover {
  color:#000;
  background-color:#FFF;
}

.navbar-brand img {
  height:32px;
  margin-top: 2px;
}

li.nav-item {
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2px;
    padding: 0 8px;
    border-bottom:3px solid transparent;
}

li.nav-item:last-child a::after {
    border-bottom: none;
}

a.btn.btn-dark.nav-link {
    padding: 5px 16px;
}

@media (max-width:992px) {
    a.btn.btn-dark.nav-link {
      display: block;
  }
}

li.nav-item:hover:last-child a::after {
    border-bottom: none !important;
}

li.nav-item:hover:last-child a::after {
    border-bottom: none !important;
}

@media (max-width:991px) {
  ul.navbar-nav {
    margin-top:1em;
  }

  li.nav-item {
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;
    display:inline-block;
  }

  li.nav-item a {
    display:inline-block;
    font-size:1.5em;
  }

  #resultsCol {
    margin-top:4em;
  }

}

@media (min-width:992px) {
  ul.navbar-nav {
    margin-top: 6px;
  }
  .carousel .slide img {
      height: 338px;
  }
  .filters-container {
      margin-bottom: 2em;
  }
}

@media (min-width:1200px) {
  .carousel .slide img {
      height: 405px;
  }
  
}

@media (min-width:1920px) {
  .carousel .slide img {
      height: 615px;
      width:820px;
  }
  
}


li.nav-item a::after {
  content: '';  
  border-bottom: 2px solid transparent;
  display:block;
}

li.nav-item:last-child {
  padding-right:0;
}

li.nav-item:hover a::after {
  content: '';  
  border-bottom: 2px solid black;
}

li.nav-item:active a::after {
  content: '';  
  border-bottom: 2px solid #bc002d;
}

li.nav-item:active a {
  color:#bc002d !important;
}
.how-it-works {
  margin-top: -3.25em;
  margin-bottom: 3em;
}

.suggested-heading {
    font-family: inherit;
    font-size: 2em;
    text-align: center;
    font-weight: 900;
    letter-spacing: 0;
    margin-bottom: 1em;
}

@media (max-width:991px) {
  .how-it-works {
    margin-top:2em;
    margin-bottom:2em;
  }
  .how-it-works .step-container {
    margin-bottom:2em;
  }

  .suggested-item {
    margin-bottom:2em;
  }
}

.step-container {
  text-align:center;
}

.step-circle {
    text-align: center;
    border: 3px solid #bc002d;
    border-radius: 100%;
    width: 2.5em;
    height: 2.5em;
    padding: 0.25em;
    line-height: 1.9em;
    font-size: 2.5em;
    font-weight: 900;
    display: inline-block;
    font-family: "Racing Sans One", "Helvetica", "Arial", sans-serif;
    background-color: #bc002d;
    color:#fff;
}

.step-container h3 {
  font-family: "Share Tech", "Helvetica", "Arial", sans-serif;
  letter-spacing:0;
  margin-top:1em;
  font-weight:900;
}
/* prevent thumbs from moving around - kinda hacky, maybe there's a way to disable it properly*/
ul.thumbs.animated {
    transform: translate3d(0px, 0px, 0px) !important;
}

#filtersheading span.badge {
    font-size: 0.4em;
    vertical-align: middle;
}

li#makeToggler,
li#yearToggler,
li#odoToggler,
li#scoreToggler,
li#colorToggler,
li#dispToggler,
li#locationToggler,
li#lotNumberToggler,
li#gearToggler {
    display: flex;
    justify-content: space-between;
}

.filters-inner .list-group .badge {
  line-height: inherit;
}

.copyLinkText {
    -webkit-animation: fadeOut 2s forwards;
    animation: fadeOut 2s forwards;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    color: #1b6ec2;
}

@-webkit-keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
}

#expandFiltersMobile {
  display:none;
  cursor: pointer;
}

@media (max-width:991px) {
  .filters-container {
    margin-top: -2em;
    -webkit-filter: none;
    filter: none;
    background-color: white;
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 1px solid #DDD;
    -webkit-transition: 300ms all ease-in-out;
    transition: 300ms all ease-in-out;
    margin-bottom: 2em;
  }

  #expandFiltersMobile {
    line-height: 1.75em;
    font-size: 1.5em;
    display:inline-block;
  }

  .filters-inner {
    overflow-y: scroll;
  }

}
@media (max-width:767px) {
  .filters-container {
      height: 50px;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .filters-container {
      height: 57px;
  }
}

.loading-spinner.isloadingmore {
  text-align: center;
  height:auto;
  width:100%;
  display: inline-block;
  left:initial;
  right:initial;
  position: relative;
  margin-top: 4em;
}

.region-heading {
  color:#666;
}

.authenticated-link-container li {
  display:inline-block;
}

#brokers-signup {
    background-color: #000;
    color:#fff;
    padding-top: 3em;
}

.broker-valueprops {
  margin-bottom:2em;
}

.become-a-preferred-broker {
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 1em;
}

@media (min-width:768px) {
  .become-a-preferred-broker {
    padding-top: 2em;
  }
}

@media (min-width:992px) {
  .become-a-preferred-broker {
    font-size:1.5em
  }
}

.broker-signup-form {
  background-color:#fff;
  color:#222428;
  padding:2em;
  border-radius: 0 1.5em;
  margin-bottom:3em;
}

.broker-signup-form button {
  margin-top:1.5em;
}

.create-alert {
  padding: 0px 8px;
}

p.small {
  font-size:0.75em;
}

.alert-container {
  padding:1.5em;
}
.broker-container {
  margin-bottom: 2em;
}

.broker-container button {
    width: 90%;
    margin: 0 5% 5%;
    bottom: 0;
    position: absolute;
}

.broker-content {
  height:100%;
  /* border: 1px solid #ccc; */
  position: relative;
}

.broker-content a.listing-link {
  display:block;
}

.broker-content .grid-img-container::before {
  box-shadow: none;
}

.broker-info {
  padding: 0.5em 1.5em 1.5em;
  margin-bottom: 4em;
}

.broker-info a:first-of-type,
.broker-info a:first-of-type:hover,
.broker-info a:first-of-type h4 {
  font-weight:700;
  color:#222428;
}


@media (max-width:767px) {

  .broker-container {
    margin-bottom:3em;
  }

}

.image-viewer a.favorite a.isFavorited {
  position: absolute;
  z-index: 1;
  right: 0.75em;
  top: 0.5em;
  font-size: 0;
}

select[name="rcrs-country"] {
  width: 100%;
  padding: 0.5em;
}

.broker-profile-info h1 {
  margin-top:0.5em;
}

.rating-number {
  margin: 0 1em;
}

@media (max-width: 991px) {

    #contact-broker {
      margin-top:4em;
  }

}

#contact-broker button {
  margin-top:1.5em;
}

.broker-profile-img,
.broker-profile-img-full {
    width:100%;
    border-radius:100%;
    box-shadow: 0 2px 10px rgba(0,0,0,0.3);
    margin-bottom: 10px;
    padding: 5px;
    background-color: #fff;
}

.broker-profile-img-full {
    margin-top: -20%;
}

.footer-links {
  padding-top:1em;
}

@media (min-width:768px) {

  .footer-links {
    text-align: right;
    padding-top:0;
  }

}

a.favorite::after {
    content: '';
    background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0"  encoding="utf-8"%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15.8 16"  xml:space="preserve"%3E%3Cstyle type="text/css"%3E .st0%7Bfill:%23CCCCCC;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class="st0" d="M4,0c1.5,0,2.6,0.8,3.4,2.5C7.7,3.2,7.9,3.7,7.9,4h0c0.3-1.2,0.7-2.1,1.4-2.9C10,0.4,10.9,0,11.9,0 c1.5,0,2.7,0.8,3.6,2.4c0.2,0.6,0.3,1.1,0.3,1.7c0,1.8-0.7,3.5-2.2,5.1L7.9,16H7.8l-6-7.4C0.6,7.1,0,5.6,0,4.1 c0-1.5,0.7-2.7,2.1-3.6C2.7,0.2,3.3,0,4,0z"/%3E%3C/g%3E%3C/svg%3E%0A');
    width: 28px;
    height: 28px;
    display: block;
    background-repeat: no-repeat;
    position: relative;
    right: 16px;
    top: 16px;
    opacity: 0.75;
    
}

a.favorite:hover::after {
    content: '';
    background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="utf-8"%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15.8 16"  xml:space="preserve"%3E%3Cstyle type="text/css"%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class="st0" d="M4,0c1.5,0,2.6,0.8,3.4,2.5C7.7,3.2,7.9,3.7,7.9,4h0c0.3-1.2,0.7-2.1,1.4-2.9C10,0.4,10.9,0,11.9,0 c1.5,0,2.7,0.8,3.6,2.4c0.2,0.6,0.3,1.1,0.3,1.7c0,1.8-0.7,3.5-2.2,5.1L7.9,16H7.8l-6-7.4C0.6,7.1,0,5.6,0,4.1 c0-1.5,0.7-2.7,2.1-3.6C2.7,0.2,3.3,0,4,0z"/%3E%3C/g%3E%3C/svg%3E%0A');
    width: 28px;
    height: 28px;
    display: block;
    background-repeat: no-repeat;
    position: relative;
    right: 16px;
    top: 16px;
    opacity: 1;
}


a.favorite:active::after {
    content: '';
    background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="utf-8"%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15.8 16"  xml:space="preserve"%3E%3Cstyle type="text/css"%3E .st0%7Bfill:%23BC2030;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class="st0" d="M4,0c1.5,0,2.6,0.8,3.4,2.5C7.7,3.2,7.9,3.7,7.9,4h0c0.3-1.2,0.7-2.1,1.4-2.9C10,0.4,10.9,0,11.9,0 c1.5,0,2.7,0.8,3.6,2.4c0.2,0.6,0.3,1.1,0.3,1.7c0,1.8-0.7,3.5-2.2,5.1L7.9,16H7.8l-6-7.4C0.6,7.1,0,5.6,0,4.1 c0-1.5,0.7-2.7,2.1-3.6C2.7,0.2,3.3,0,4,0z"/%3E%3C/g%3E%3C/svg%3E%0A');
    width: 28px;
    height: 28px;
    display: block;
    background-repeat: no-repeat;
    position: relative;
    right: 16px;
    top: 16px;
    opacity: 1;
    fill: red;
    box-shadow: 0,0,5px,5px rgba(255,122,140,1);
    filter: brightness(0) saturate(100%) invert(7%) sepia(97%) saturate(6249%) hue-rotate(341deg) brightness(100%) contrast(102%);
}

a.isFavorited::after {
  content: '';
  background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="utf-8"%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15.8 16"  xml:space="preserve"%3E%3Cstyle type="text/css"%3E .st0%7Bfill:%23BC2030;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class="st0" d="M4,0c1.5,0,2.6,0.8,3.4,2.5C7.7,3.2,7.9,3.7,7.9,4h0c0.3-1.2,0.7-2.1,1.4-2.9C10,0.4,10.9,0,11.9,0 c1.5,0,2.7,0.8,3.6,2.4c0.2,0.6,0.3,1.1,0.3,1.7c0,1.8-0.7,3.5-2.2,5.1L7.9,16H7.8l-6-7.4C0.6,7.1,0,5.6,0,4.1 c0-1.5,0.7-2.7,2.1-3.6C2.7,0.2,3.3,0,4,0z"/%3E%3C/g%3E%3C/svg%3E%0A');
  width: 28px;
  height: 28px;
  display: block;
  background-repeat: no-repeat;
  position: relative;
  right: 16px;
  top: 16px;
  opacity: 1;
  box-shadow: 0,0,5px,5px rgba(255,122,140,1);
  filter: brightness(0) saturate(100%) invert(7%) sepia(97%) saturate(6249%) hue-rotate(341deg) brightness(100%) contrast(102%);
}

a.isFavorited:hover::after {
  content: '';
  background-image: url('data:image/svg+xml,%3C%3Fxml version="1.0" encoding="utf-8"%3F%3E%3C!-- Generator: Adobe Illustrator 24.2.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 15.8 16"  xml:space="preserve"%3E%3Cstyle type="text/css"%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class="st0" d="M4,0c1.5,0,2.6,0.8,3.4,2.5C7.7,3.2,7.9,3.7,7.9,4h0c0.3-1.2,0.7-2.1,1.4-2.9C10,0.4,10.9,0,11.9,0 c1.5,0,2.7,0.8,3.6,2.4c0.2,0.6,0.3,1.1,0.3,1.7c0,1.8-0.7,3.5-2.2,5.1L7.9,16H7.8l-6-7.4C0.6,7.1,0,5.6,0,4.1 c0-1.5,0.7-2.7,2.1-3.6C2.7,0.2,3.3,0,4,0z"/%3E%3C/g%3E%3C/svg%3E%0A');
  width: 28px;
  height: 28px;
  display: block;
  background-repeat: no-repeat;
  position: relative;
  right: 16px;
  top: 16px;
  opacity: 1;
}

@media screen and (max-width: 768px) {

  a.favorite,
  a.isFavorited,
  a.isFavorited::after,
  a.isFavorited:hover::after,
  a.isFavorited:active::after,
  a.favorite::after,
  a.favorite:hover::after,
  a.favorite:active::after {
    right: 25px;
  }

  label.labelFavorited {
    right: 65px;
  }
}

.validation-error-input {
  border-color: #bc002d;
}

.validation-error-label {
  color: #bc002d;
}
